import React from "react"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { Container } from "../../styles/common.styles"
import { HexagonTitle } from "../../elements/hexagon-title"
import { DAOurPartnersMain } from "../../styles/digital-assets/da-our-partners.styles"
import { SvgIcon } from "../../helpers/common"
import { unoptimzeGatsbyImageSource } from "../../helpers/common"
import { graphql, useStaticQuery } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import ArrowRight from "../../assets/svg/arrow-right.svg"

const tempItems = [
  {
    title: "Growth Strategy",
    text:
      "As crypto networks mature, we can help scale by providing support in key areas such as compliance, treasury, capital raising, PR, branding, custody, regulatory engagement, operational best practices and hiring.",
    itemIcon: "growth",
  },
  {
    title: "Blockchain Development",
    text:
      "With access to over 20 blockchain developers on a full-time basis, we can help portfolio companies develop their technology and applications.",
    itemIcon: "blockchain",
  },
  {
    title: "Asymmetric Information Advantage",
    text:
      "Investing in both public and private markets is a powerful feedback loop, allowing us to provide insights on market trends and opportunities for new market entrants.",
    itemIcon: "asymmetric",
  },
  {
    title: "Active Network Participation",
    text:
      "As a participatory crypto fund, active in both liquid and illiquid markets, we can stake tokens and run nodes to help bootstrap decentralised networks, provide security and support the network’s long-term viability.",

    itemIcon: "globalNetwork",
  },
  {
    title: "Voting & Governance",
    text:
      "Participation is key to decentralised networks and an active role in governance aspects helps shape the evolution of protocols and ensure the success of the networks.",
    itemIcon: "governance",
  },
]

export const DAOurPartners = ({
  items,
  text,
  title,
  hexagonData,

  riskFactorsText,
  riskFactorsLinkText,
  disclosuresTitle,
  disclaimerTextLinkText,
  disclaimerText,

  modalProps = { openModal: () => undefined }
}) => {
  const {
    img: { childImageSharp },
  } = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "partnership-globe.png" }) {
        childImageSharp {
          original {
            src
            width
          }
          fluid(maxWidth: 1060) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ top: 100, bottom: 100 }}
    >
      {({ isVisible }) => (
        <DAOurPartnersMain
          className={`to-animate ${isVisible ? "is-in-vp" : ""} cutout-top`}
        >
          <Container>
            <HexagonTitle variation="circle" number={hexagonData?.sectionNum}>{hexagonData?.name}</HexagonTitle>
            <SectionTitle
              text={title}
              delay={200}
              responsive={[
                { breakpoint: 767, charLimit: 17 },
                { breakpoint: 480, charLimit: 14 },
              ]}
            />
            <div className="main-text-col">
              <SectionText
                text={text}
                variation="large-text"
              />
            </div>
            <div className="support-list">
              {(items || []).map(({ itemIcon, title }, i) => (
                <div
                  key={i}
                  className="support-li-wrap"
                  style={{ transitionDelay: `${600 + i * 100}ms` }}
                >
                  <div className={`support-li-icon ${itemIcon}`}>
                    <SvgIcon icon={itemIcon} />
                  </div>
                  <div className="support-li-title">{ReactHtmlParser((title || '').replace('<br />', ' <br />'))}</div>
                </div>
              ))}
              <div
                className="list-line"
                style={{ transitionDelay: `${600 + (items || []).length * 100}ms` }}
              />
            </div>

            {modalProps &&
              <div className="modal-btns-wrap" style={{ transitionDelay: `${600 + (items || []).length * 100}ms` }}>
                <div className="discalaim-title">
                  {disclosuresTitle}
                </div>

                <div className="modal-btn-item">
                  <ArrowRight />{disclaimerText}
                  <button
                    className="modal-btn"
                    onClick={() => {
                      modalProps?.openModal({ override: { acceptType: '', type: 'disclosure' } })
                    }}
                  >
                    {disclaimerTextLinkText}
                  </button>
                </div>

                <div className="modal-btn-item">
                  <ArrowRight />{riskFactorsText}
                  <button
                    className="modal-btn"
                    onClick={() => {
                      modalProps?.openModal({ override: { acceptType: '', type: 'risk' } })
                    }}
                  >
                    {riskFactorsLinkText}
                  </button>
                </div>
              </div>
            }

          </Container>
          <div className="globe-img">
            <Img fluid={unoptimzeGatsbyImageSource(childImageSharp)} />
          </div>
        </DAOurPartnersMain>
      )}
    </VisibilitySensor>
  )
}
