import styled from "styled-components";

export const DAOfficesMain = styled.div`
  padding: 90px 0 50px;

  .office-table-warp {
    padding: 0 30px;
    width: 95%;
    margin: 58px auto;
    
    ${props => props.theme.max('lt', (props) => (`
      width: 100%;
    `), props)}
    
    ${props => props.theme.max('sm', (props) => (`
      padding: 0 20px;
    `), props)}
  }

  .office-table {
    border-top: 1px solid rgba(0,0,0,.1);
    font-size: 22px;
    width: 100%;
    ${props => props.theme.max('mac', (props) => (`
      font-size: 20px;
    `), props)}
    
    ${props => props.theme.max('lt', (props) => (`
      font-size: 18px;
    `), props)}
    ${props => props.theme.max('sm', (props) => (`
      display: block;
    `), props)}

    td {
      vertical-align: top;
    }
  }

  .da-office-country {
    color: ${props => props?.theme?.colors?.secondary};
  }
  
  .da-office-country,
  .da-office-city {
    width: 18%;
    ${props => props.theme.max('mac', (props) => (`
      width: 16%;
    `), props)}
  }

  .da-office-city {
    ${props => props.theme.max('lt', (props) => (`
      width: 12%;
    `), props)}
  }
  .da-office-link-w {
    text-align: right;
    
    ${props => props.theme.max('lt', (props) => (`
      width: 13%;
    `), props)}
    
    ${props => props.theme.max('sm', (props) => (`
      text-align: left;
    `), props)}

    a {
      color: ${props => props.theme?.colors?.primary200};
    }
  }

  .da-office-table-row {
    ${props => props.theme.max('sm', (props) => (`
      display: block;
      border-bottom: 1px solid rgba(0,0,0,.1);
      padding-top: 30px;
    `), props)}

    tr:last-child {
      td {
        border-bottom: 1px solid rgba(0,0,0,.1);
        padding-bottom: 50px;

        ${props => props.theme.max('sm', (props) => (`
          padding: 0;
          border-bottom: none;
        `), props)}
      }
    }

    td {
      padding: 50px 0 0;

      ${props => props.theme.max('sm', (props) => (`
        display: block;
        margin-bottom: 30px;
        width: 100%;
        padding: 0;
        
        &.hidden-td {
          display: none;
        }
        
        &.da-office-city {
          margin-bottom: 16px;
        }
      `), props)}
    }
  }

  .sub-item-wrap:not(:first-child) {
    margin-top: 49px;
  }

  &.to-animate {
    .da-office-table-row {
      transform: translate(0, 80px);
      opacity: 0;
    }
  }

  &.to-animate:not(.is-in-vp) {
    .da-office-table-row {
      transition-delay: 0ms !important;
    }
  }

  &.to-animate.is-in-vp {
    .da-office-table-row {
      transition: ${800}ms;
      transform: translate(0);
      opacity: 1;
    }
  }
`