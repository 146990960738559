import styled from "styled-components"

import {
  SectionTitleMain,
  SectionTextMain,
  containerSideOffset,
} from "../common.styles"

export const GlobalFootprintMain = styled.div`
  background-color: ${props => props.theme.colors.primary300};
  position: relative;
  padding-top: 60px;
  padding-bottom: 136px;

  ${props =>
    props.theme.max(
      "sm",
      props => `
        padding-bottom: 80px;
  `,
      props
    )}

  .section-title {
    ${containerSideOffset()};
  }

  ${SectionTitleMain} {
    color: ${props => props.theme.colors.white};
  }

  ${SectionTextMain} {
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    max-width: 475px;
  }

  .side-col {
    ${containerSideOffset()};
    margin-top: -12.8%;

    ${props =>
    props.theme.max(
      "lg",
      props => `
         margin-top: -7.8%;
  `,
      props
    )}

    ${props =>
    props.theme.max(
      "sm",
      props => `
        margin-top: 0;
  `,
      props
    )}
  }

  .map-img {
    padding-top: 30px;

    ${props =>
    props.theme.max(
      "sm",
      props => `
        display: none;
  `,
      props
    )}
  }

  .mobile-content {
    position: relative;
    padding-bottom: 90px;

    ${props =>
    props.theme.min(
      "sm",
      props => `
        display: none;
  `,
      props
    )}
  }

  .cities {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
  }

  .city {
    font-size: 20px;
    padding-bottom: 34px;
    color: ${props => props.theme.colors.green100};
  }

  .map-img-mobile {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .map-img-inner {
    position: relative;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100vw;
      width: 100%;
      height: 100%;
    }
  }
  @keyframes markerAnim {
    0% {
      opacity: .5;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: .5;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: .5;
    }
  }
  @keyframes dotMarkerAnim {
    0% {
      transform: scale(.5);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(.5);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(.5);
    }
  }

  [class^="da-marker"] {
    animation-name: markerAnim;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-delay: .4s;
  }

  .da-marker-1 {
    animation-duration: 4.1s; 
  }
  .da-marker-2 {
    animation-duration: 4.2s; 
  }
  .da-marker-3 {
    animation-duration: 4.3s; 
  }
  .da-marker-4 {
    animation-duration: 4.4s; 
  }
  .da-marker-5 {
    animation-duration: 4.5s; 
  }
  .da-marker-6 {
    animation-duration: 4.6s; 
  }
  
  [class^="da-dot"] {
    animation-name: dotMarkerAnim;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    transform-box: fill-box;
    transform-origin: center center;
  }
  .da-dot-marker-1 {
    animation-duration: 4.1s; 
  }
  .da-dot-marker-2 {
    animation-duration: 4.2s; 
  }
  .da-dot-marker-3 {
    animation-duration: 4.3s; 
  }
  .da-dot-marker-4 {
    animation-duration: 4.4s; 
  }
  .da-dot-marker-5 {
    animation-duration: 4.5s; 
  }
  .da-dot-marker-6 {
    animation-duration: 4.6s; 
  }
  
  .da-fp-cline {
    stroke-dasharray: 300;
    animation-name: arcAnimationSmall;
    animation-timing-function: cubic-bezier(0.140, -0.060, 0.850, 1.060);
    animation-iteration-count: infinite;
  }

  .da-fp-cline-1 {
    animation-duration: 5.4s; 
  }
  .da-fp-cline-2 {
    animation-duration: 5.2s; 
  }
  .da-fp-cline-3 {
    animation-duration: 5.3s; 
  }
  .da-fp-cline-4 {
    animation-duration: 5.4s; 
  }
  .da-fp-cline-5 {
    animation-duration: 5.5s; 
  }
  .da-fp-cline-6 {
    animation-duration: 5.6s; 
  }

  .da-fp-cline-6,
  .da-fp-cline-5,
  .da-fp-cline-4 {
    stroke-dasharray: 500;
    animation-name: arcAnimationMedium;
  }

  .da-fp-cline-3 {
    stroke-dasharray: 1000;
    animation-name: arcAnimationLarge;
  }

  @keyframes arcAnimationLarge {
    0% {
      stroke-dashoffset: 1000;
    }
    33% {
      stroke-dashoffset: ${1000 * 5};
    }
    50% {
      stroke-dashoffset: ${1000 * 3};
    }
    68% {
      stroke-dashoffset: ${1000 * 5};
    }
    100% {
      stroke-dashoffset: 1000;
    }

  } 

  @keyframes arcAnimationSmall {
    0% {
      stroke-dashoffset: 300;
    }
    40% {
      stroke-dashoffset: ${300 * 5};
    }
    60% {
      stroke-dashoffset: ${300 * 3};
    }
    75% {
      stroke-dashoffset: ${300 * 5};
    }
    100% {
      stroke-dashoffset: 300;
    }
  }
  @keyframes arcAnimationMedium {
    0% {
      stroke-dashoffset: 500;
    }
    40% {
      stroke-dashoffset: ${500 * 5};
    }
    60% {
      stroke-dashoffset: ${500 * 3};
    }
    75% {
      stroke-dashoffset: ${500 * 5};
    }
    100% {
      stroke-dashoffset: 500;
    }

  } 

  
  &.to-animate {
    .city {
      transform: translate(0, 80px);
      opacity: 0;
    }
  }

  &.to-animate:not(.is-in-vp) {
    .city {
      transition-delay: 0ms !important;
    }
  }

  &.to-animate.is-in-vp {
    .city {
      transition: ${800}ms;
      transform: translate(0);
      opacity: 1;
    }
  }
`
