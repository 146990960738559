import styled from "styled-components"
import { col, containerSideOffset, SectionTextMain, SectionTitleMain } from "../common.styles"

export const DAOurTeamMain = styled.div`
  padding-top: 170px;
  padding-bottom: 110px;
  background-color: ${props => props.theme.colors.primary300};
  color: ${props => props.theme.colors.white};

  ${SectionTitleMain} {
    color: ${props => props.theme.colors.white};
  }

  &.cutout-top:before {
    background-color: ${props => props.theme.colors.primary};
    max-height: 95px;
    left: 48%;
    width: 52%;
  }


  .flex-wrap {
    margin-left: -24px;
    margin-right: -24px;
  }

  .side-col {
    ${col(10, "c")};
    
    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
    `), props)}
  }
  .side-col-2 {
    margin-left: 5.3125%;
    ${props => props.theme.max('sm', (props) => (`
      margin-left: 0;
    `), props)}
  }

  .lg-circl-wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .sm-circl-parent {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    ${props => props.theme.max('sm', (props) => (`
      flex-direction: column;
    `), props)}
  }

  .sm-circl-wrap {
    display: flex;

    ${props => props.theme.max('sm', (props) => (`
      margin-left: auto;
      margin-right: auto;
    `), props)}
  }

  .space-top {
    margin-top: 68px;
  }

  .h-line {
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
    height: 5px;

    ${props => props.theme.max('sm', (props) => (`
      height: 45px;
      flex: 0 0 45px;
      min-width: 5px;
      width: 5px;
    `), props)}

    > svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      min-width: 200px;
      height: 5px;
      transform: translate(0, -50%);

      ${props => props.theme.max('sm', (props) => (`
        left: 50%;
        transform-origin: left center;
        transform: rotate(90deg);
      `), props)}
    }
  }

  .v-line {
    position: relative;
    height: 55px;
    overflow: hidden;

    > svg {
      position: absolute;
      left: 50%;
      top: 0;
      transform-origin: left center;
      transform: rotate(90deg);
    }
  }

  .lg-circl-parent {
    padding-top: 110px;
  }

  .da-team-footnote {
    font-size: 14px;
    line-height: 1.5;
    color: ${props => props.theme.colors.primary400};
  }

  .to-animate {
    .da-team-footnote {
      transform: translate(0, 100px);
      opacity: 0;
    }

    .v-line {
      transform-origin: top center;
      transform: scaleY(0);
    }

    .h-line {
      transform: scaleX(0);
      ${props => props.theme.max('sm', (props) => (`
        transform: scaleY(0);
      `), props)}
    }
    .h-line-0 {
      transform-origin: right center;
    }
    .h-line-1 {
      transform-origin: left center;
    }
  }

  .to-animate:not(.is-in-vp) {
    .da-team-footnote {
      transition-delay: 500ms !important;
    }
  }

  .to-animate.is-in-vp {
    .da-team-footnote {
      transition: ${800}ms ease 500ms;
      transform: translate(0);
      opacity: 1;
    }

    .v-line {
      transition: ${800}ms ease 800ms;
      transform: scaleY(1);
    }
    
    .h-line {
      transition: ${800}ms ease 1200ms;
      transform: scaleX(1);
      ${props => props.theme.max('sm', (props) => (`
        transform: scaleY(1);
      `), props)}
    }
  }
`

export const DotCircleItemMain = styled.div`
  position: relative;

  svg {
    max-width: 100%;
    height: 100%;
  }

  .dci-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .dci-num {
    font-size: 150px;
    font-family: ${props => props.theme.fonts.tertiary};
  }

  .dci-text {
    color: ${props => props.theme.colors.secondary100};
  }

  &.small {
    max-width: 305px;
    ${props => props.theme.max('lt', (props) => (`
      max-width: 243px;
    `), props)}

    .dci-num {
      font-size: 100px;
      ${props => props.theme.max('lt', (props) => (`
        font-size: 80px;
      `), props)}
    }

    .dci-text {
      font-size: 20px;
      ${props => props.theme.max('lt', (props) => (`
        font-size: 16px;
      `), props)}
    }
  }

  &.large {
    ${props => props.theme.max('lt', (props) => (`
      max-width: 331px;
    `), props)}

    .dci-num {
      ${props => props.theme.max('lt', (props) => (`
        font-size: 100px;
      `), props)}
    }
    
    .dci-text {
      font-size: 30px;
      
      ${props => props.theme.max('lt', (props) => (`
        font-size: 22px;
      `), props)}
    }
  }

  .to-animate & {
    .dci-content {
      opacity: 0;
    }
    svg {
      transform: scale(0);
      opacity: 0;
    }
  }

  .to-animate:not(.is-in-vp) & {
    .dci-content {

    }
  }

  .to-animate.is-in-vp & {
    .dci-content {
      opacity: 1;
      transition: ${1500}ms ease 500ms;
    }

    svg {
      transition: ${1500}ms ease;
      transform: scale(1);
      opacity: 1;
    }
  }
  &.sm-dci-0 {
    .dci-content,
    svg {
      .to-animate.is-in-vp & {
        transition-delay: 1000ms;

        ${props => props.theme.max('sm', (props) => (`
          transition-delay: 300ms ;
        `), props)}
      }
    }
  }
  &.sm-dci-1 {
    .dci-content,
    svg {
      .to-animate.is-in-vp & {
        transition-delay: 500ms;
      }
    }
  }
  &.sm-dci-2 {
    .dci-content,
    svg {
      .to-animate.is-in-vp & {
        transition-delay: 1000ms;
      }
    }
  }
`
