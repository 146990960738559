import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"
import { DAHeroMain } from "../../styles/digital-assets/da-hero.styles"
import { SectionTitle } from "../../elements/section-title"
import { unoptimzeGatsbyImageSource } from "../../helpers/common"
import { Container } from "../../styles/common.styles"

export const DAHero = ({ title, background }) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
      {({ isVisible }) => (
        <DAHeroMain className={`to-animate ${isVisible ? "is-in-vp" : ""}`}>
          <Container>
            <div className="da-hero-inner">
              <SectionTitle text={title} />
              {background &&
                <div className="da-hero-img">
                  <Img fluid={unoptimzeGatsbyImageSource(background)} />
                </div>
              }
            </div>
          </Container>
        </DAHeroMain>
      )}
    </VisibilitySensor>
  )
}
