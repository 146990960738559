import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { HexagonTitle } from "../../elements/hexagon-title"
import { DAOurTeamMain, DotCircleItemMain } from "../../styles/digital-assets/da-our-team.styles"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { Container } from "../../styles/common.styles"
import { useCountUp } from 'react-countup'

import DotCircleSvg from "../../assets/svg/dot-circle.svg"
import DotLineSvg from "../../assets/svg/dot-line.svg"

const DotCircleItem = ({ number, sufix, text, className, isVisible }) => {
  const { countUp, start, reset } = useCountUp({
    start: 1,
    end: number,
    duration: 4,
    easingFn: function (t, b, c, d) {
      return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    }
  });

  React.useEffect(() => {
    if (isVisible) {
      start();
    }

    if (!isVisible) {
      reset();
    }

  }, [isVisible])

  return (
    <DotCircleItemMain className={`${className}`}>
      <DotCircleSvg />
      <div className="dci-content">
        <div className="dci-num"><span>{countUp}</span><span>{sufix}</span></div>
        <div className="dci-text">{text}</div>
      </div>
    </DotCircleItemMain>
  );
}

export const DAOurTeam = ({
  hexagonData: { name, sectionNum, },
  title,
  textLeft,
  textRight,
  diagramDescription,
  footnote,
  primaryStat,
  secondaryStats,
}) => {

  return (
    <DAOurTeamMain className="cutout-top">
      <Container>
        <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
          {({ isVisible }) =>
            <div className={`flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <div className="title-col pad-col">
                <HexagonTitle variation="circle" number={sectionNum} className="hex-txt-white">
                  {name}
                </HexagonTitle>
                <SectionTitle text={title} />
              </div>
            </div>
          }
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
          {({ isVisible }) =>
            <div className={`flex-wrap to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <div className="pad-col side-col side-col-1">
                <SectionText text={textLeft} variation="large-text" />
              </div>
              <div className="pad-col side-col side-col-2">
                <SectionText text={textRight} variation="large-text" />
              </div>
            </div>
          }
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
          {({ isVisible }) =>
            <div className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <div className="flex-wrap lg-circl-parent">
                <div className="lg-circl-wrap">
                  <DotCircleItem
                    isVisible={isVisible}
                    number={primaryStat?.number}
                    sufix={primaryStat?.sufix}
                    text={primaryStat?.text}
                    className={'large'}
                  />
                  <div className="v-line">
                    {/* <DotLineSvg /> */}
                  </div>
                </div>
              </div>


              <div className="flex-wrap sm-circl-parent">
                {(secondaryStats || []).map(({ item: { number, sufix, text } }, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="sm-circl-wrap">
                        <DotCircleItem
                          isVisible={isVisible}
                          number={number}
                          sufix={sufix}
                          text={text}
                          className={`small sm-dci sm-dci-${i}`}
                        />
                      </div>
                      {(secondaryStats || []).length - 1 > i && (
                        <div className={`h-line h-line-${i}`}>
                          <DotLineSvg />
                        </div>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          }
        </VisibilitySensor>
        <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
          {({ isVisible }) =>
            <div className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
              <SectionText
                text={diagramDescription}
                variation="large-text space-top"
              />

              <div className="da-team-footnote">
                {footnote}
              </div>
            </div>
          }
        </VisibilitySensor>

      </Container>
    </DAOurTeamMain>
  )
}

