import React from "react"
import { SectionTitle } from "../../elements/section-title"
import { Container } from "../../styles/common.styles"
import { DAOfficesMain } from "../../styles/da-offices.style"
import VisibilitySensor from "react-visibility-sensor"

export const DAOffices = ({ digitalassetsacf, hexagonData = {} }) => {

  const items = digitalassetsacf?.offices?.locations || [];
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <DAOfficesMain className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <Container>
            <SectionTitle text={digitalassetsacf?.offices?.title || ''} variation={'small-title'} />
          </Container>

          <div className="office-table-warp">
            <table className="office-table">

              {items.map((location, i) => {
                const address = (location?.address || []).reduce((prev, current) => !prev ? current.text : `${prev}, ${current.text}`, '');
                const locItems = location?.items;
                return (
                  <tbody
                    key={i}
                    className={'da-office-table-row'}
                    style={{ transitionDelay: `${600 + i * 100}ms` }}
                  >
                    {locItems.map((item, index) => {
                      return (
                        <tr key={`tr${index}`}>
                          <td className={`da-office-country ${index === 0 ? '' : 'hidden-td'}`}>
                            {index === 0 && location?.country}
                          </td>
                          <td className={`da-office-city ${index === 0 ? '' : 'hidden-td'}`}>
                            {index === 0 && location?.city}
                          </td>
                          <td>
                            {item?.address || ''}
                          </td>
                          <td className="da-office-link-w">
                            <a
                              href={item?.link || '/'}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {digitalassetsacf?.offices.directionsButtonText}
                            </a>

                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                )
              })}
            </table>
          </div>
        </DAOfficesMain>
      }
    </VisibilitySensor>
  )
}