import styled from "styled-components"

import { col, SectionTextMain, HexagonTitleMain } from "../common.styles"

export const DAOurPartnersMain = styled.div`
  padding-top: 170px;
  padding-bottom: 260px;
  position: relative;
  
  ${props => props.theme.max('sm', (props) => (`
    padding-bottom: 60px;
  `), props)}

  &.cutout-top:before {
    background-color: ${props => props.theme.colors.primary300};
    max-height: 95px;
    left: 48%;
    width: 52%;
  }

  ${HexagonTitleMain} {
    margin-bottom: 76px;
  }

  .main-text-col {
    padding-bottom: 12px;

    ${SectionTextMain} {
      max-width: 1040px;
    }
  }

  .text-col {
    ${SectionTextMain} {
      max-width: 497px;
      color: ${props => props.theme.colors.secondary100};
    }
  }

  .globe-img {
    position: absolute;
    z-index: -1;
    width: 52%;
    bottom: 0;
    right: -60px;

    ${props =>
    props.theme.max(
      "sm",
      props => `
        position: static;
        width: 100%;
        padding: 0 30px;
  `,
      props
    )}
  }

  .list-line {
    display: block;
    border-bottom: 1px dashed ${props => props?.theme?.colors?.secondary50};
    max-width: 520px;

    ${props => props.theme.max('sm', (props) => (`
      margin: 0 auto;
    `), props)}
  }

  .support-list {
    padding-top: 56px;
    padding-bottom: 78px;
  }

  .support-li-icon {
    margin-right: 30px;
    
    ${props => props.theme.max('sm', (props) => (`
      margin-right: 0;
      margin-bottom: 26px;
    `), props)}
  }

  .support-item-wrap {
    ${col(33.3333)}
    padding-top: 47px;
    padding-bottom: 29px;

    ${props =>
    props.theme.max(
      "sm",
      props => `
    ${col(50)}
  `,
      props
    )}

    ${props =>
    props.theme.max(
      "sf",
      props => `
    ${col(100)}
    padding-top: 20px;
    padding-bottom: 3px;
  `,
      props
    )}
  }

  .support-item {
    max-width: 358px;

    ${props =>
    props.theme.max(
      "sf",
      props => `
        max-width: 100%;
  `,
      props
    )}
  }

  .support-item-icon {
    margin-bottom: 1.5em;
    height: 36px;

    &.macro {
      transform: translate(0, -0.3em);
    }
  }

  .support-item-title {
    color: ${props => props.theme.colors.green100};
    font-size: 20px;
    margin-bottom: 0.6em;
  }

  .support-item-text {
    color: ${props => props.theme.colors.primary};
    line-height: 1.5;
  }

  &.to-animate {
    .modal-btns-wrap,
    .list-line,
    .support-li-wrap {
      transform: translate(0, 80px);
      opacity: 0;
    }
  }

  &.to-animate:not(.is-in-vp) {
    .modal-btns-wrap,
    .list-line,
    .support-li-wrap {
      transition-delay: 0ms !important;
    }
  }

  &.to-animate.is-in-vp {
    .modal-btns-wrap,
    .list-line,
    .support-li-wrap {
      transition: ${800}ms;
      transform: translate(0);
      opacity: 1;
    }
  }

  .support-li-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 80px;

    ${props => props.theme.max('sm', (props) => (`
      display: block;
      text-align: center;
      margin-bottom: 120px;
    `), props)}
  }

  .support-li-title {
    font-size: 20px;
    color: ${props => props?.theme?.colors?.green100};
    ${props => props.theme.min('sm', (props) => (`
      br {
        display: none;

      }
    `), props)}
  }

  .modal-btn-item {
    vertical-align: top;
    margin-bottom: 14px;
    color: ${props => props?.theme?.colors?.primary200};
    font-size: 22px;

    svg { 
      position: relative;
      margin-right: .3em;
      transform: translateY(-10%);

      path {
        fill: currentColor;
      }
    }
  }

  .modal-btn {
    cursor: pointer;
    appearance: none;
    border: none;
    background: none;
    text-decoration: underline;    
    color: currentColor;

    &:hover {
      text-decoration: none;
    }

  }

  .discalaim-title {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .modal-btns-wrap {
     ${props => props.theme.max('sm', (props) => (`
      padding-bottom: 90px;
    `), props)}
  }
`
