import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { GlobalFootprintMain } from "../../styles/digital-assets/da-global-footprint.styles"
import Img from "gatsby-image"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { unoptimzeGatsbyImageSource } from "../../helpers/common"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "../../styles/common.styles"
import MapSvg from "../../assets/svg/footprint-section-map.svg"

const titleResponsiveBP = [
  { breakpoint: 767, charLimit: 19 },
  { breakpoint: 480, charLimit: 16 },
]

const cities = [
  {
    id: 1,
    city: "Austin",
  },
  {
    id: 2,
    city: "Chicago",
  },
  {
    id: 3,
    city: "New York",
  },
  // {
  //   id: 4,
  //   city: "Cayman Islands",
  // },
  {
    id: 5,
    city: "London",
  },
  {
    id: 6,
    city: "Jersey",
  },
  {
    id: 7,
    city: "Geneva",
  },
  {
    id: 8,
    city: "Singapore",
  },

  {
    id: 9,
    city: "Abu Dhabi",
  },
]

export const DAGlobalFootprint = ({
  secondaryTitle,
  text,
  title,
}) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "footprint-section-map.png" }) {
        childImageSharp {
          original {
            src
            width
          }
          fluid(maxWidth: 1700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgMobile: file(
        relativePath: { eq: "footprint-section-map-mobile.png" }
      ) {
        childImageSharp {
          original {
            src
            width
          }
          fluid(maxWidth: 780) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 20, bottom: 20 }}>
      {({ isVisible }) => (
        <GlobalFootprintMain
          // NOTE: check if this is needed 
          className={`to-animate ${isVisible ? "is-in-vp" : ""}`}
        >
          <div className="section-title">
            {/* <SectionTitle
              text={title}
              responsive={titleResponsiveBP}
            /> */}
          </div>
          <div className="map-img">
            <div className="map-img-inner">
              <Img
                fluid={unoptimzeGatsbyImageSource(data?.img?.childImageSharp)}
              />
              <MapSvg />
            </div>
          </div>
          <div className="mobile-content">
            <div className="map-img-mobile">
              <Img
                fluid={unoptimzeGatsbyImageSource(
                  data?.imgMobile?.childImageSharp
                )}
              />
            </div>
            <div className="cities">
              {cities.map(({ city, id }, i) => (
                <div key={id} className="city" style={{ transitionDelay: `${600 + i * 100}ms` }}>
                  {/* {city} */}
                </div>
              ))}
            </div>
          </div>
          <div className="side-col">
            <SectionTitle
              text={secondaryTitle}
              variation="smallest-title"
              responsive={titleResponsiveBP}
            />
            <SectionText text={text} />
          </div>
        </GlobalFootprintMain>
      )}
    </VisibilitySensor>
  )
}
