import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { Container } from "../../styles/common.styles"
import { DAWorkTogetherMain } from "../../styles/digital-assets/da-work-together.styles"

export const DAWorkTogether = ({ hexagonData, title }) => {
  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ top: 100, bottom: 100 }}
    >
      {({ isVisible }) => (
        <Container>
          <DAWorkTogetherMain
            className={`to-animate ${isVisible ? "is-in-vp" : ""}`}
          >
            <div className="title-col">
              <HexagonTitle variation="circle" number={hexagonData?.sectionNum}>{hexagonData?.name}</HexagonTitle>
              <SectionTitle text={title} />
            </div>
          </DAWorkTogetherMain>
        </Container>
      )}
    </VisibilitySensor>
  )
}
