import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { ContactInformation } from "../contact/contact-information-section"

export const DAContact = ({ title, hexagonData = {} }) => {
  const {
    wpgraphql: {
      themeSettings: {
        themesettingsacf: contactDetails
      }
    },
  } = useStaticQuery(graphql`
  query {
    wpgraphql {
      themeSettings {
        themesettingsacf {
          contactIrEmail
          contactIrLabel
          contactIrPhoneLink
          contactIrPhoneText
          otherEnquiriesEmail
          otherEnquiriesLabel
          otherEnquiriesPhoneLink
          otherEnquiriesPhoneText
          pressEnquiriesGroupName
          pressEnquiriesLabel
          pressEnquiriesUkEmail
          pressEnquiriesUkEmailText
          pressEnquiriesUkPhoneLink
          pressEnquiriesUsaEmail
          pressEnquiriesUkPhoneText
          pressEnquiriesUsaEmailText
          pressEnquiriesUsaPhoneLink
          pressEnquiriesUsaPhoneText
          siteSocialLabel
          siteSocialLinkedinLink
          siteSocialLinkedinLinkText
          siteSocialBhDigitalLinkedinLink
          siteSocialBhDigitalLinkedinLinkText
        }
      }
    }
  }
`)

  return (
    <ContactInformation
      hexagonData={hexagonData}
      firstTitle={title}
      contactDetails={{
        ...contactDetails,
        siteSocialLinkedinLink: contactDetails?.siteSocialBhDigitalLinkedinLink, // https://uk.linkedin.com/company/bh-digital-brevanhoward
        siteSocialLinkedinLinkText: contactDetails?.siteSocialBhDigitalLinkedinLinkText // linkedin.com/bh-digital-brevanhoward
      }}
    />
  )
}