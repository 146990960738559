import styled from "styled-components"

import { SectionTitleMain } from "../common.styles"

export const DAHeroMain = styled.div`
  background-color: ${props => props.theme.colors.primary};

  .da-hero-inner {
    padding-top: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 100vh;

    ${props =>
      props.theme.max(
        "md",
        props => `
       padding-top: 80px;
  `,
        props
      )}
  }

  ${SectionTitleMain} {
    position: absolute;
    z-index: 5;
    top: 32%;
    color: ${props => props.theme.colors.white};
    text-align: center;
    margin-bottom: 0.3em;

    ${props =>
      props.theme.max(
        "sm",
        props => `
       top: 40%;
  `,
        props
      )}
  }

  .da-hero-img {
    width: 100%;
    max-width: 940px;
  }
`
