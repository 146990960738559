import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { SectionTitle } from "../../elements/section-title"

import { HexagonTitle } from "../../elements/hexagon-title"
import { RevealImage } from "../../elements/reveal-image"
import { SectionText } from "../../elements/section-text"
import { DAAboutMain } from "../../styles/digital-assets/da-about"
import { QuoteBox } from "../../elements/quote-box"

export const DAAbout = ({
  image1,
  image1Mobile,
  ceoImage,
  hexagonData,
  title,
  text,
  quoteText,
  companyPosition,
  fullName,
  textRight,
  quoteFootnote,
}) => {

  return (
    <DAAboutMain>
      {image1Mobile && <div className="mobile-only"><RevealImage image={image1Mobile} variation="from-right" /></div>}
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div>
            <div className="flex-wrap first-row">
              <div className={`main-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
                <HexagonTitle variation="circle" number={hexagonData?.sectionNum} className="hex-txt-white">{hexagonData?.name}</HexagonTitle>
                <SectionTitle text={title} responsive={[{ breakpoint: 768, charLimit: 15 }]} />

              </div>
              <div className={`image-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
                <div className="side-col-img desktop-only">{image1 && <RevealImage image={image1} variation="from-right" />}</div>

              </div>
            </div>
            <div className={`flex-wrap to-animate  ${isVisible ? 'is-in-vp' : ''}`}>
              <div className="main-col">{text && <SectionText variation={"large-text"} text={text} />}</div>
              <div className="side-col">{textRight && <SectionText variation={"large-text"} text={textRight} />}</div>
            </div>
          </div>
        }
      </VisibilitySensor>


      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className={`quote-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
            <QuoteBox variation="circle" text={quoteText} name={fullName} label={companyPosition} image={ceoImage} quoteFootnote={quoteFootnote} />
          </div>
        }
      </VisibilitySensor>

    </DAAboutMain>
  )
}