import styled from "styled-components"

import {
  col,
  containerSideOffset,
  HexagonTitleMain,
  SectionTextMain,
  SectionTitleMain,
} from "../common.styles"

export const DAAboutMain = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 0 0 45px;

  ${props =>
    props.theme.max(
      "lt",
      () => `
  padding: 220px 0 230px;
`
    )}

  ${props =>
    props.theme.max(
      "md",
      props => `
  padding: 180px 0 80px;
`,
      props
    )}

${props =>
    props.theme.max(
      "sm",
      props => `
  padding: 0 0 50px;
`,
      props
    )}

.main-col {
    ${containerSideOffset()}
    ${col(6, "c")}

    ${props =>
    props.theme.max(
      "mac",
      props => `
    ${col(30)}
  `,
      props
    )}

  ${props =>
    props.theme.max(
      "md",
      () => `
    padding-top: 10px;
    ${col(50)}
  `
    )}

  ${props =>
    props.theme.max(
      "sm",
      props => `
    ${col(100)}
  `,
      props
    )}
  }

  ${HexagonTitleMain} {
    margin-bottom: 76px;
  }

  ${SectionTitleMain} {
    color: ${props => props.theme.colors.white};
    margin-bottom: 0.56em;

    ${props =>
    props.theme.max(
      "sm",
      () => `
    margin-bottom: 0.76em;
  `
    )}
  }

  .image-col {
    ${col(49.85)}
    margin-left: auto;

    ${props =>
    props.theme.max(
      "mac",
      () => `
      ${col(46.7)}
      `
    )}

    ${props =>
    props.theme.max(
      "crt",
      () => `
      ${col(42)}
      `
    )}
  }

  .side-col {
    ${containerSideOffset("right", 3)}
    ${col(37.35)}
    margin-left: auto;

    ${props =>
    props.theme.max(
      "md",
      () => `
      ${col(45)}
      `
    )}
    
    ${props =>
    props.theme.max(
      "sm",
      () => `
      ${col(100)}
      `
    )}

    ${SectionTextMain} {
      ${col(67)}

      ${props =>
    props.theme.max(
      "mac",
      props => `
      ${col(80)}
    `,
      props
    )}
    
      ${props =>
    props.theme.max(
      "crt",
      props => `
      ${col(100)}
    `,
      props
    )}
    }
  }

  .side-col-img {
    margin-left: auto;
    margin-bottom: 93px;
  }

  .img-text-col {
    ${containerSideOffset()}
    ${col(6.2, "c")}
  padding-top: 22px;

    ${props =>
    props.theme.max(
      "md",
      () => `
    ${col(45)}
  `
    )}

    ${props =>
    props.theme.max(
      "sm",
      () => `
    padding-top: 56px;
    order: 2;
    ${col(100)}
  `
    )}
  }

  .img-col {
    ${col(11, "c")}
    margin-left: auto;

    ${props =>
    props.theme.max(
      "md",
      () => `
    order: 1;
  `
    )}

    ${props =>
    props.theme.max(
      "sm",
      () => `
    ${col(100)}
    ${containerSideOffset()}
  `
    )}
  }

  .quote-col {
    ${col(100)}
    padding: 184px 0 222px;

    ${props =>
    props.theme.max(
      "mac",
      () => `
    padding-top: 80px;
  `
    )}

    ${props =>
    props.theme.max(
      "md",
      props => `
    padding: 50px 0 10px;
  `,
      props
    )}
  }

  .to-animate {
    .quote-content {
      opacity: 0;
      transform: translate(-100px, 0);

      ${props =>
    props.theme.max(
      "sm",
      () => `
      transform: translate(0, 100px);
    `
    )}
    }

    .quote-sig-wrap {
      opacity: 0;
    }
  }

  .to-animate:not(.is-in-vp) {
    .quote-sig-wrap,
    .quote-content {
      transition-delay: 0ms !important;
    }
  }

  .to-animate.is-in-vp {
    .quote-content {
      transition: ${800}ms ease 800ms;
      transform: translate(0);
      opacity: 1;

      ${props =>
    props.theme.max(
      "sm",
      () => `
      transform: translate(0);
    `
    )}
    }

    .quote-sig-wrap {
      transition: ${800}ms ease 800ms;
      opacity: 1;
    }
  }

  .img-col-wrap {
    align-items: center;
    padding-top: 60px;
  }

  .first-row {   
    .main-col {
      padding-top: 309px;

      ${props => props.theme.min(
      "fhd",
      () => `
        padding-top: 450px;
      `)}

      ${props => props.theme.max(
        "mac",
        () => `
        padding-top: 209px;
      `)}

      ${props => props.theme.max(
          "sm",
          () => `
        padding-top: 90px;
      ` )}
    }
  }
`
